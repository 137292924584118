tinymce.IconManager.add('default', {
  icons: {
    'align-center': '<svg width="24" height="24"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.25 7C3.25 6.58579 3.58579 6.25 4 6.25H20C20.4142 6.25 20.75 6.58579 20.75 7C20.75 7.41421 20.4142 7.75 20 7.75H4C3.58579 7.75 3.25 7.41421 3.25 7ZM6.25 12C6.25 11.5858 6.58579 11.25 7 11.25H17C17.4142 11.25 17.75 11.5858 17.75 12C17.75 12.4142 17.4142 12.75 17 12.75H7C6.58579 12.75 6.25 12.4142 6.25 12ZM5 16.25C4.58579 16.25 4.25 16.5858 4.25 17C4.25 17.4142 4.58579 17.75 5 17.75H19C19.4142 17.75 19.75 17.4142 19.75 17C19.75 16.5858 19.4142 16.25 19 16.25H5Z" fill="#787878"/></svg>',
    'align-justify': '<svg width="24" height="24"><path d="M5 5h14c.6 0 1 .4 1 1s-.4 1-1 1H5a1 1 0 110-2zm0 4h14c.6 0 1 .4 1 1s-.4 1-1 1H5a1 1 0 110-2zm0 4h14c.6 0 1 .4 1 1s-.4 1-1 1H5a1 1 0 010-2zm0 4h14c.6 0 1 .4 1 1s-.4 1-1 1H5a1 1 0 010-2z" fill-rule="evenodd"/></svg>',
    'align-left': '<svg width="24" height="24"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.25 7C3.25 6.58579 3.58579 6.25 4 6.25H18C18.4142 6.25 18.75 6.58579 18.75 7C18.75 7.41421 18.4142 7.75 18 7.75H4C3.58579 7.75 3.25 7.41421 3.25 7ZM3.25 12C3.25 11.5858 3.58579 11.25 4 11.25H14C14.4142 11.25 14.75 11.5858 14.75 12C14.75 12.4142 14.4142 12.75 14 12.75H4C3.58579 12.75 3.25 12.4142 3.25 12ZM4 16.25C3.58579 16.25 3.25 16.5858 3.25 17C3.25 17.4142 3.58579 17.75 4 17.75H18C18.4142 17.75 18.75 17.4142 18.75 17C18.75 16.5858 18.4142 16.25 18 16.25H4Z" fill="#787878"/></svg>',
    'align-right': '<svg width="24" height="24"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.25 7C5.25 6.58579 5.58579 6.25 6 6.25H20C20.4142 6.25 20.75 6.58579 20.75 7C20.75 7.41421 20.4142 7.75 20 7.75H6C5.58579 7.75 5.25 7.41421 5.25 7ZM9.25 12C9.25 11.5858 9.58579 11.25 10 11.25H20C20.4142 11.25 20.75 11.5858 20.75 12C20.75 12.4142 20.4142 12.75 20 12.75H10C9.58579 12.75 9.25 12.4142 9.25 12ZM6 16.25C5.58579 16.25 5.25 16.5858 5.25 17C5.25 17.4142 5.58579 17.75 6 17.75H20C20.4142 17.75 20.75 17.4142 20.75 17C20.75 16.5858 20.4142 16.25 20 16.25H6Z" fill="#787878"/></svg>',
    'bold': '<svg width="24" height="24"><path fill-rule="evenodd" clip-rule="evenodd" d="M6 4.39288C5.58579 4.39288 5.25 4.72867 5.25 5.14288V12V12V18.8571C5.25 19.2714 5.58579 19.6071 6 19.6071H13.7143C14.8225 19.6071 15.8853 19.1669 16.669 18.3833C17.4526 17.5996 17.8929 16.5368 17.8929 15.4286C17.8929 14.3203 17.4526 13.2575 16.669 12.4739C16.3576 12.1625 16.0022 11.9054 15.6174 11.7086C15.684 11.65 15.7488 11.5892 15.8118 11.5262C16.5955 10.7425 17.0357 9.67968 17.0357 8.57146C17.0357 7.46323 16.5955 6.40039 15.8118 5.61676C15.0282 4.83312 13.9654 4.39288 12.8571 4.39288H6ZM6.75 12.75H12.8571C12.8621 12.75 12.8671 12.75 12.8721 12.75H13.7143C14.4247 12.75 15.106 13.0322 15.6083 13.5345C16.1107 14.0369 16.3929 14.7182 16.3929 15.4286C16.3929 16.139 16.1107 16.8203 15.6083 17.3226C15.106 17.8249 14.4247 18.1071 13.7143 18.1071H6.75V12.75ZM12.869 11.25C13.5751 11.2469 14.2516 10.965 14.7512 10.4655C15.2535 9.96316 15.5357 9.28186 15.5357 8.57146C15.5357 7.86105 15.2535 7.17975 14.7512 6.67742C14.2488 6.17509 13.5675 5.89288 12.8571 5.89288H6.75V11.25H12.869Z" fill="#787878"/></svg>',
    'checklist-rtl': '<svg width="24" height="24"><path d="M5 17h8c.6 0 1 .4 1 1s-.4 1-1 1H5a1 1 0 010-2zm0-6h8c.6 0 1 .4 1 1s-.4 1-1 1H5a1 1 0 010-2zm0-6h8c.6 0 1 .4 1 1s-.4 1-1 1H5a1 1 0 110-2zm14.2 11c.2-.4.6-.5.9-.3.3.2.4.6.2 1L18 20c-.2.3-.7.4-1 0l-1.3-1.3a.7.7 0 010-1c.3-.2.7-.2 1 0l.7.9 1.7-2.8zm0-6c.2-.4.6-.5.9-.3.3.2.4.6.2 1L18 14c-.2.3-.7.4-1 0l-1.3-1.3a.7.7 0 010-1c.3-.2.7-.2 1 0l.7.9 1.7-2.8zm0-6c.2-.4.6-.5.9-.3.3.2.4.6.2 1L18 8c-.2.3-.7.4-1 0l-1.3-1.3a.7.7 0 010-1c.3-.2.7-.2 1 0l.7.9 1.7-2.8z" fill-rule="evenodd"/></svg>',
    'checklist': '<svg width="24" height="24"><path d="M11 17h8c.6 0 1 .4 1 1s-.4 1-1 1h-8a1 1 0 010-2zm0-6h8c.6 0 1 .4 1 1s-.4 1-1 1h-8a1 1 0 010-2zm0-6h8a1 1 0 010 2h-8a1 1 0 010-2zM7.2 16c.2-.4.6-.5.9-.3.3.2.4.6.2 1L6 20c-.2.3-.7.4-1 0l-1.3-1.3a.7.7 0 010-1c.3-.2.7-.2 1 0l.7.9 1.7-2.8zm0-6c.2-.4.6-.5.9-.3.3.2.4.6.2 1L6 14c-.2.3-.7.4-1 0l-1.3-1.3a.7.7 0 010-1c.3-.2.7-.2 1 0l.7.9 1.7-2.8zm0-6c.2-.4.6-.5.9-.3.3.2.4.6.2 1L6 8c-.2.3-.7.4-1 0L3.8 6.9a.7.7 0 010-1c.3-.2.7-.2 1 0l.7.9 1.7-2.8z" fill-rule="evenodd"/></svg>',
    'close': '<svg width="24" height="24"><path d="M17.3 8.2L13.4 12l3.9 3.8a1 1 0 01-1.5 1.5L12 13.4l-3.8 3.9a1 1 0 01-1.5-1.5l3.9-3.8-3.9-3.8a1 1 0 011.5-1.5l3.8 3.9 3.8-3.9a1 1 0 011.5 1.5z" fill-rule="evenodd"/></svg>',
    'code-sample': '<svg width="24" height="26"><path d="M7.1 11a2.8 2.8 0 01-.8 2 2.8 2.8 0 01.8 2v1.7c0 .3.1.6.4.8.2.3.5.4.8.4.3 0 .4.2.4.4v.8c0 .2-.1.4-.4.4-.7 0-1.4-.3-2-.8-.5-.6-.8-1.3-.8-2V15c0-.3-.1-.6-.4-.8-.2-.3-.5-.4-.8-.4a.4.4 0 01-.4-.4v-.8c0-.2.2-.4.4-.4.3 0 .6-.1.8-.4.3-.2.4-.5.4-.8V9.3c0-.7.3-1.4.8-2 .6-.5 1.3-.8 2-.8.3 0 .4.2.4.4v.8c0 .2-.1.4-.4.4-.3 0-.6.1-.8.4-.3.2-.4.5-.4.8V11zm9.8 0V9.3c0-.3-.1-.6-.4-.8-.2-.3-.5-.4-.8-.4a.4.4 0 01-.4-.4V7c0-.2.1-.4.4-.4.7 0 1.4.3 2 .8.5.6.8 1.3.8 2V11c0 .3.1.6.4.8.2.3.5.4.8.4.2 0 .4.2.4.4v.8c0 .2-.2.4-.4.4-.3 0-.6.1-.8.4-.3.2-.4.5-.4.8v1.7c0 .7-.3 1.4-.8 2-.6.5-1.3.8-2 .8a.4.4 0 01-.4-.4v-.8c0-.2.1-.4.4-.4.3 0 .6-.1.8-.4.3-.2.4-.5.4-.8V15a2.8 2.8 0 01.8-2 2.8 2.8 0 01-.8-2zm-3.3-.4c0 .4-.1.8-.5 1.1-.3.3-.7.5-1.1.5-.4 0-.8-.2-1.1-.5-.4-.3-.5-.7-.5-1.1 0-.5.1-.9.5-1.2.3-.3.7-.4 1.1-.4.4 0 .8.1 1.1.4.4.3.5.7.5 1.2zM12 13c.4 0 .8.1 1.1.5.4.3.5.7.5 1.1 0 1-.1 1.6-.5 2a3 3 0 01-1.1 1c-.4.3-.8.4-1.1.4a.5.5 0 01-.5-.5V17a3 3 0 001-.2l.6-.6c-.6 0-1-.2-1.3-.5-.2-.3-.3-.7-.3-1 0-.5.1-1 .5-1.2.3-.4.7-.5 1.1-.5z" fill-rule="evenodd"/></svg>',
    'chevron-down': '<svg width="10" height="10"><path d="M8.7 2.2c.3-.3.8-.3 1 0 .4.4.4.9 0 1.2L5.7 7.8c-.3.3-.9.3-1.2 0L.2 3.4a.8.8 0 010-1.2c.3-.3.8-.3 1.1 0L5 6l3.7-3.8z" fill-rule="nonzero"/></svg>',
    'chevron-left': '<svg width="10" height="10"><path d="M7.8 1.3L4 5l3.8 3.7c.3.3.3.8 0 1-.4.4-.9.4-1.2 0L2.2 5.7a.8.8 0 010-1.2L6.6.2C7 0 7.4 0 7.8.2c.3.3.3.8 0 1.1z" fill-rule="nonzero"/></svg>',
    'chevron-right': '<svg width="10" height="10"><path d="M2.2 1.3a.8.8 0 010-1c.4-.4.9-.4 1.2 0l4.4 4.1c.3.4.3.9 0 1.2L3.4 9.8c-.3.3-.8.3-1.2 0a.8.8 0 010-1.1L6 5 2.2 1.3z" fill-rule="nonzero"/></svg>',
    'chevron-up': '<svg width="10" height="10"><path d="M8.7 7.8L5 4 1.3 7.8c-.3.3-.8.3-1 0a.8.8 0 010-1.2l4.1-4.4c.3-.3.9-.3 1.2 0l4.2 4.4c.3.3.3.9 0 1.2-.3.3-.8.3-1.1 0z" fill-rule="nonzero"/></svg>',
    'checkmark': '<svg width="24" height="24"><path d="M18.2 5.4a1 1 0 011.6 1.2l-8 12a1 1 0 01-1.5.1l-5-5a1 1 0 111.4-1.4l4.1 4.1 7.4-11z" fill-rule="nonzero"/></svg>',
    'edit-image': '<svg width="24" height="24"><path d="M18 16h2V7a2 2 0 00-2-2H7v2h11v9zM6 17h15a1 1 0 010 2h-1v1a1 1 0 01-2 0v-1H6a2 2 0 01-2-2V7H3a1 1 0 110-2h1V4a1 1 0 112 0v13zm3-5.3l1.3 2 3-4.7 3.7 6H7l2-3.3z" fill-rule="nonzero"/></svg>',
    'image-options': '<svg width="24" height="24"><path d="M6 10a2 2 0 00-2 2c0 1.1.9 2 2 2a2 2 0 002-2 2 2 0 00-2-2zm12 0a2 2 0 00-2 2c0 1.1.9 2 2 2a2 2 0 002-2 2 2 0 00-2-2zm-6 0a2 2 0 00-2 2c0 1.1.9 2 2 2a2 2 0 002-2 2 2 0 00-2-2z" fill-rule="nonzero"/></svg>',
    'image': '<svg width="24" height="24" style="display: inline-block; position: relative;"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.77778 4.75C5.21015 4.75 4.75 5.21015 4.75 5.77778V18.2222C4.75 18.7585 5.16079 19.1989 5.6849 19.2459L15.0846 9.4799C15.226 9.333 15.4211 9.25 15.625 9.25C15.8289 9.25 16.024 9.333 16.1654 9.4799L19.25 12.6847V5.77778C19.25 5.21015 18.7898 4.75 18.2222 4.75H5.77778ZM20.75 14.5441V5.77778C20.75 4.38172 19.6183 3.25 18.2222 3.25H5.77778C4.38172 3.25 3.25 4.38172 3.25 5.77778V18.2222C3.25 19.6183 4.38172 20.75 5.77778 20.75H5.9986C5.99947 20.75 6.00035 20.75 6.00122 20.75H18.2222C19.6183 20.75 20.75 19.6183 20.75 18.2222V14.5467C20.75 14.5458 20.75 14.5449 20.75 14.5441ZM19.25 14.8478L15.625 11.0815L7.76284 19.25H18.2222C18.7898 19.25 19.25 18.7898 19.25 18.2222V14.8478ZM8.5 7.75C8.08579 7.75 7.75 8.08579 7.75 8.5C7.75 8.91421 8.08579 9.25 8.5 9.25C8.91421 9.25 9.25 8.91421 9.25 8.5C9.25 8.08579 8.91421 7.75 8.5 7.75ZM6.25 8.5C6.25 7.25736 7.25736 6.25 8.5 6.25C9.74264 6.25 10.75 7.25736 10.75 8.5C10.75 9.74264 9.74264 10.75 8.5 10.75C7.25736 10.75 6.25 9.74264 6.25 8.5Z" fill="#787878"/></svg> <span class="image_addon" style="color: #787878; font-size: 0.82rem; height: 27px; display: inline-block; line-height: 27px; font-weight: 500;">Add image</span>',
    'info': '<svg width="24" height="24"><path d="M12 4a7.8 7.8 0 015.7 2.3A8 8 0 1112 4zm-1 3v2h2V7h-2zm3 10v-1h-1v-5h-3v1h1v4h-1v1h4z" fill-rule="evenodd"/></svg>',
    'notice': '<svg width="24" height="24"><path d="M17.8 9.8L15.4 4 20 8.5v7L15.5 20h-7L4 15.5v-7L8.5 4h7l2.3 5.8zm0 0l2.2 5.7-2.3-5.8zM13 17v-2h-2v2h2zm0-4V7h-2v6h2z" fill-rule="evenodd"/></svg>',
    'italic': '<svg width="24" height="24"><path fill-rule="evenodd" clip-rule="evenodd" d="M14.5901 4.39284H17.9994C18.4137 4.39284 18.7494 4.72863 18.7494 5.14284C18.7494 5.55706 18.4137 5.89284 17.9994 5.89284H15.0923L10.5119 18.1071H13.7143C14.1285 18.1071 14.4643 18.4429 14.4643 18.8571C14.4643 19.2713 14.1285 19.6071 13.7143 19.6071H9.44664C9.43529 19.6074 9.42391 19.6074 9.41251 19.6071H6C5.58579 19.6071 5.25 19.2713 5.25 18.8571C5.25 18.4429 5.58579 18.1071 6 18.1071H8.90993L13.4903 5.89284H10.2852C9.87094 5.89284 9.53516 5.55706 9.53516 5.14284C9.53516 4.72863 9.87094 4.39284 10.2852 4.39284H14.5553C14.5668 4.39257 14.5784 4.39257 14.5901 4.39284Z" fill="#787878"/></svg>',
    'ordered-list': '<svg width="24" height="24"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.36151 2.63635H4.33949V6.99999H3.28694V3.62073H3.26137L2.28552 4.21732V3.30539L3.36151 2.63635ZM9.00001 11.25C8.58579 11.25 8.25001 11.5858 8.25001 12C8.25001 12.4142 8.58579 12.75 9.00001 12.75H22C22.4142 12.75 22.75 12.4142 22.75 12C22.75 11.5858 22.4142 11.25 22 11.25H9.00001ZM2.32174 14H5.55185V13.1498H3.78339V13.1221L4.3054 12.6406C5.25143 11.816 5.49858 11.3963 5.49858 10.8956C5.49858 10.1094 4.85725 9.57669 3.86648 9.57669C2.90128 9.57669 2.25569 10.1328 2.25782 11.0213H3.25711C3.25711 10.6271 3.50001 10.3991 3.86222 10.3991C4.21805 10.3991 4.47586 10.6186 4.47586 10.9787C4.47586 11.3047 4.27131 11.5263 3.91336 11.848L2.32174 13.2415V14ZM9.00001 18.25C8.58579 18.25 8.25001 18.5858 8.25001 19C8.25001 19.4142 8.58579 19.75 9.00001 19.75H22C22.4142 19.75 22.75 19.4142 22.75 19C22.75 18.5858 22.4142 18.25 22 18.25H9.00001ZM3.98154 21.0596C5.00427 21.0596 5.73083 20.5185 5.7287 19.7599C5.73083 19.223 5.37927 18.8395 4.70597 18.7606V18.7266C5.21094 18.6541 5.5689 18.3153 5.56677 17.8253C5.5689 17.1136 4.92543 16.5767 3.99006 16.5767C3.0483 16.5767 2.36222 17.1221 2.3537 17.8977H3.36151C3.37003 17.5994 3.6385 17.3991 3.99006 17.3991C4.32458 17.3991 4.55256 17.6016 4.55043 17.8935C4.55256 18.196 4.2841 18.4048 3.89631 18.4048H3.47231V19.1548H3.89631C4.33736 19.1548 4.62501 19.3721 4.62074 19.679C4.62501 19.9879 4.3608 20.2031 3.9858 20.2031C3.61293 20.2031 3.33807 20.005 3.32742 19.7173H2.26634C2.27486 20.5057 2.98438 21.0596 3.98154 21.0596ZM9.00001 4.24999C8.58579 4.24999 8.25001 4.58578 8.25001 4.99999C8.25001 5.4142 8.58579 5.74999 9.00001 5.74999H22C22.4142 5.74999 22.75 5.4142 22.75 4.99999C22.75 4.58578 22.4142 4.24999 22 4.24999H9.00001Z" fill="#787878"/></svg>',
    'lock': '<svg width="24" height="24"><path d="M16.3 11c.2 0 .3 0 .5.2l.2.6v7.4c0 .3 0 .4-.2.6l-.6.2H7.8c-.3 0-.4 0-.6-.2a.7.7 0 01-.2-.6v-7.4c0-.3 0-.4.2-.6l.5-.2H8V8c0-.8.3-1.5.9-2.1.6-.6 1.3-.9 2.1-.9h2c.8 0 1.5.3 2.1.9.6.6.9 1.3.9 2.1v3h.3zM10 8v3h4V8a1 1 0 00-.3-.7A1 1 0 0013 7h-2a1 1 0 00-.7.3 1 1 0 00-.3.7z" fill-rule="evenodd"/></svg>',
    'page-break': '<svg width="24" height="24"><g fill-rule="evenodd"><path d="M5 11c.6 0 1 .4 1 1s-.4 1-1 1a1 1 0 010-2zm3 0h1c.6 0 1 .4 1 1s-.4 1-1 1H8a1 1 0 010-2zm4 0c.6 0 1 .4 1 1s-.4 1-1 1a1 1 0 010-2zm3 0h1c.6 0 1 .4 1 1s-.4 1-1 1h-1a1 1 0 010-2zm4 0c.6 0 1 .4 1 1s-.4 1-1 1a1 1 0 010-2zM7 3v5h10V3c0-.6.4-1 1-1s1 .4 1 1v7H5V3c0-.6.4-1 1-1s1 .4 1 1zM6 22a1 1 0 01-1-1v-7h14v7c0 .6-.4 1-1 1a1 1 0 01-1-1v-5H7v5c0 .6-.4 1-1 1z"/></g></svg>',
    'paragraph': '<svg width="24" height="24"><path fill-rule="evenodd" d="M10 5h7a1 1 0 010 2h-1v11a1 1 0 01-2 0V7h-2v11a1 1 0 01-2 0v-6c-.5 0-1 0-1.4-.3A3.4 3.4 0 016.8 10a3.3 3.3 0 010-2.8 3.4 3.4 0 011.8-1.8L10 5z"/></svg>',
    'paste-column-after': '<svg width="24" height="24"><path fill-rule="evenodd" d="M12 1a3 3 0 012.8 2H18c1 0 2 .8 2 1.9V7h-2V5h-2v1c0 .6-.4 1-1 1H9a1 1 0 01-1-1V5H6v13h7v2H6c-1 0-2-.8-2-1.9V5c0-1 .8-2 1.9-2H9.2A3 3 0 0112 1zm8 7v12h-6V8h6zm-1.5 1.5h-3v9h3v-9zM12 3a1 1 0 100 2 1 1 0 000-2z"/></svg>',
    'paste-column-before': '<svg width="24" height="24"><path fill-rule="evenodd" d="M12 1a3 3 0 012.8 2H18c1 0 2 .8 2 1.9V18c0 1-.8 2-1.9 2H11v-2h7V5h-2v1c0 .6-.4 1-1 1H9a1 1 0 01-1-1V5H6v2H4V5c0-1 .8-2 1.9-2H9.2A3 3 0 0112 1zm-2 7v12H4V8h6zM8.5 9.5h-3v9h3v-9zM12 3a1 1 0 100 2 1 1 0 000-2z"/></svg>',
    'paste-row-after': '<svg width="24" height="24"><path fill-rule="evenodd" d="M12 1a3 3 0 012.8 2H18c1 0 2 .8 2 1.9V11h-2V5h-2v1c0 .6-.4 1-1 1H9a1 1 0 01-1-1V5H6v13h14c0 1-.8 2-1.9 2H6c-1 0-2-.8-2-1.9V5c0-1 .8-2 1.9-2H9.2A3 3 0 0112 1zm10 11v5H8v-5h14zm-1.5 1.5h-11v2h11v-2zM12 3a1 1 0 100 2 1 1 0 000-2z"/></svg>',
    'paste-row-before': '<svg width="24" height="24"><path fill-rule="evenodd" d="M12 1a3 3 0 012.8 2H18c1 0 2 .8 2 1.9V7h-2V5h-2v1c0 .6-.4 1-1 1H9a1 1 0 01-1-1V5H6v13h12v-4h2v4c0 1-.8 2-1.9 2H6c-1 0-2-.8-2-1.9V5c0-1 .8-2 1.9-2H9.2A3 3 0 0112 1zm10 7v5H8V8h14zm-1.5 1.5h-11v2h11v-2zM12 3a1 1 0 100 2 1 1 0 000-2z"/></svg>',
    'paste-text': '<svg width="24" height="24"><path d="M18 9V5h-2v1c0 .6-.4 1-1 1H9a1 1 0 01-1-1V5H6v13h3V9h9zM9 20H6a2 2 0 01-2-2V5c0-1.1.9-2 2-2h3.2A3 3 0 0112 1a3 3 0 012.8 2H18a2 2 0 012 2v4h1v12H9v-1zm1.5-9.5v9h9v-9h-9zM12 3a1 1 0 00-1 1c0 .5.4 1 1 1s1-.5 1-1-.4-1-1-1zm0 9h6v2h-.5l-.5-1h-1v4h.8v1h-3.6v-1h.8v-4h-1l-.5 1H12v-2z" fill-rule="nonzero"/></svg>',
    'redo': '<svg width="24" height="24"><path fill-rule="evenodd" clip-rule="evenodd" d="M15.1006 4.61249C14.8077 4.3196 14.3329 4.3196 14.04 4.61249C13.7471 4.90539 13.7471 5.38026 14.04 5.67315L17.0453 8.67847H8.57115C7.46292 8.67847 6.40009 9.11871 5.61645 9.90234C4.83282 10.686 4.39258 11.7488 4.39258 12.857V18.857C4.39258 19.2713 4.72836 19.607 5.14258 19.607C5.55679 19.607 5.89258 19.2713 5.89258 18.857V12.857C5.89258 12.1466 6.17478 11.4653 6.67711 10.963C7.17944 10.4607 7.86075 10.1785 8.57115 10.1785H17.0454L14.04 13.1839C13.7471 13.4768 13.7471 13.9517 14.04 14.2446C14.3329 14.5375 14.8077 14.5375 15.1006 14.2446L19.3666 9.97863C19.5144 9.84165 19.6069 9.64586 19.6069 9.42847C19.6069 9.21017 19.5136 9.01365 19.3647 8.87659L15.1006 4.61249Z" fill="#787878"/></svg>',
    'reload': '<svg width="24" height="24"><g fill-rule="nonzero"><path d="M5 22.1l-1.2-4.7v-.2a1 1 0 011-1l5 .4a1 1 0 11-.2 2l-2.2-.2a7.8 7.8 0 008.4.2 7.5 7.5 0 003.5-6.4 1 1 0 112 0 9.5 9.5 0 01-4.5 8 9.9 9.9 0 01-10.2 0l.4 1.4a1 1 0 11-2 .5zM13.6 7.4c0-.5.5-1 1-.9l2.8.2a8 8 0 00-9.5-1 7.5 7.5 0 00-3.6 7 1 1 0 01-2 0 9.5 9.5 0 014.5-8.6 10 10 0 0110.9.3l-.3-1a1 1 0 012-.5l1.1 4.8a1 1 0 01-1 1.2l-5-.4a1 1 0 01-.9-1z"/></g></svg>',
    'restore-draft': '<svg width="24" height="24"><g fill-rule="evenodd"><path d="M17 13c0 .6-.4 1-1 1h-4V8c0-.6.4-1 1-1s1 .4 1 1v4h2c.6 0 1 .4 1 1z"/><path d="M4.7 10H9a1 1 0 010 2H3a1 1 0 01-1-1V5a1 1 0 112 0v3l2.5-2.4a9.2 9.2 0 0110.8-1.5A9 9 0 0113.4 21c-2.4.1-4.7-.7-6.5-2.2a1 1 0 111.3-1.5 7.2 7.2 0 0011.6-3.7 7 7 0 00-3.5-7.7A7.2 7.2 0 008 7L4.7 10z" fill-rule="nonzero"/></g></svg>',
    'resize-handle': '<svg width="10" height="10"><g fill-rule="nonzero"><path d="M8.1 1.1A.5.5 0 119 2l-7 7A.5.5 0 111 8l7-7zM8.1 5.1A.5.5 0 119 6l-3 3A.5.5 0 115 8l3-3z"/></g></svg>',
    'more-drawer': '<svg width="24" height="24"><path d="M6 10a2 2 0 00-2 2c0 1.1.9 2 2 2a2 2 0 002-2 2 2 0 00-2-2zm12 0a2 2 0 00-2 2c0 1.1.9 2 2 2a2 2 0 002-2 2 2 0 00-2-2zm-6 0a2 2 0 00-2 2c0 1.1.9 2 2 2a2 2 0 002-2 2 2 0 00-2-2z" fill-rule="nonzero"/></svg>',
    'resize': '<svg width="24" height="24"><path d="M4 5c0-.3.1-.5.3-.7.2-.2.4-.3.7-.3h6c.3 0 .5.1.7.3.2.2.3.4.3.7 0 .3-.1.5-.3.7a1 1 0 01-.7.3H7.4L18 16.6V13c0-.3.1-.5.3-.7.2-.2.4-.3.7-.3.3 0 .5.1.7.3.2.2.3.4.3.7v6c0 .3-.1.5-.3.7a1 1 0 01-.7.3h-6a1 1 0 01-.7-.3 1 1 0 01-.3-.7c0-.3.1-.5.3-.7.2-.2.4-.3.7-.3h3.6L6 7.4V11c0 .3-.1.5-.3.7a1 1 0 01-.7.3 1 1 0 01-.7-.3A1 1 0 014 11V5z" fill-rule="evenodd"/></svg>',
    'strike-through': '<svg width="24" height="24"><g fill-rule="evenodd"><path d="M15.6 8.5c-.5-.7-1-1.1-1.3-1.3-.6-.4-1.3-.6-2-.6-2.7 0-2.8 1.7-2.8 2.1 0 1.6 1.8 2 3.2 2.3 4.4.9 4.6 2.8 4.6 3.9 0 1.4-.7 4.1-5 4.1A6.2 6.2 0 017 16.4l1.5-1.1c.4.6 1.6 2 3.7 2 1.6 0 2.5-.4 3-1.2.4-.8.3-2-.8-2.6-.7-.4-1.6-.7-2.9-1-1-.2-3.9-.8-3.9-3.6C7.6 6 10.3 5 12.4 5c2.9 0 4.2 1.6 4.7 2.4l-1.5 1.1z"/><path d="M5 11h14a1 1 0 010 2H5a1 1 0 010-2z" fill-rule="nonzero"/></g></svg>',
    'underline': '<svg width="24" height="24"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.25 5C8.25 4.58579 7.91421 4.25 7.5 4.25C7.08579 4.25 6.75 4.58579 6.75 5V12.3846C6.75 15.3298 9.08272 17.75 12 17.75C14.9173 17.75 17.25 15.3298 17.25 12.3846V5C17.25 4.58579 16.9142 4.25 16.5 4.25C16.0858 4.25 15.75 4.58579 15.75 5V12.3846C15.75 14.5374 14.0533 16.25 12 16.25C9.94672 16.25 8.25 14.5374 8.25 12.3846V5ZM6 19.25C5.58579 19.25 5.25 19.5858 5.25 20C5.25 20.4142 5.58579 20.75 6 20.75H18C18.4142 20.75 18.75 20.4142 18.75 20C18.75 19.5858 18.4142 19.25 18 19.25H6Z" fill="#787878"/></svg>',
    'undo': '<svg width="24" height="24"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.95862 5.67315C10.2515 5.38026 10.2515 4.90539 9.95862 4.61249C9.66573 4.3196 9.19086 4.3196 8.89796 4.61249L4.61842 8.89204C4.59423 8.91567 4.57164 8.94093 4.55082 8.96763C4.45166 9.09478 4.39258 9.25472 4.39258 9.42847C4.39258 9.43202 4.3926 9.43558 4.39265 9.43913C4.39383 9.52298 4.40898 9.60668 4.43812 9.68642C4.47444 9.78584 4.53248 9.8791 4.61225 9.95887L8.89796 14.2446C9.19086 14.5375 9.66573 14.5375 9.95862 14.2446C10.2515 13.9517 10.2515 13.4768 9.95862 13.1839L6.95317 10.1785H15.4283C16.1387 10.1785 16.82 10.4607 17.3223 10.963C17.8247 11.4653 18.1069 12.1466 18.1069 12.857V18.857C18.1069 19.2713 18.4427 19.607 18.8569 19.607C19.2711 19.607 19.6069 19.2713 19.6069 18.857V12.857C19.6069 11.7488 19.1666 10.686 18.383 9.90234C17.5994 9.11871 16.5365 8.67847 15.4283 8.67847H6.95331L9.95862 5.67315Z" fill="#787878"/></svg>',
    'unlink': '<svg width="24" height="24"><path d="M6.2 12.3a1 1 0 011.4 1.4l-2 2a2 2 0 102.6 2.8l4.8-4.8a1 1 0 000-1.4 1 1 0 111.4-1.3 2.9 2.9 0 010 4L9.6 20a3.9 3.9 0 01-5.5-5.5l2-2zm11.6-.6a1 1 0 01-1.4-1.4l2.1-2a2 2 0 10-2.7-2.8L11 10.3a1 1 0 000 1.4A1 1 0 119.6 13a2.9 2.9 0 010-4L14.4 4a3.9 3.9 0 015.5 5.5l-2 2zM7.6 6.3a.8.8 0 01-1 1.1L3.3 4.2a.7.7 0 111-1l3.2 3.1zM5.1 8.6a.8.8 0 010 1.5H3a.8.8 0 010-1.5H5zm5-3.5a.8.8 0 01-1.5 0V3a.8.8 0 011.5 0V5zm6 11.8a.8.8 0 011-1l3.2 3.2a.8.8 0 01-1 1L16 17zm-2.2 2a.8.8 0 011.5 0V21a.8.8 0 01-1.5 0V19zm5-3.5a.7.7 0 110-1.5H21a.8.8 0 010 1.5H19z" fill-rule="nonzero"/></svg>',
    'unlock': '<svg width="24" height="24"><path d="M16 5c.8 0 1.5.3 2.1.9.6.6.9 1.3.9 2.1v3h-2V8a1 1 0 00-.3-.7A1 1 0 0016 7h-2a1 1 0 00-.7.3 1 1 0 00-.3.7v3h.3c.2 0 .3 0 .5.2l.2.6v7.4c0 .3 0 .4-.2.6l-.6.2H4.8c-.3 0-.4 0-.6-.2a.7.7 0 01-.2-.6v-7.4c0-.3 0-.4.2-.6l.5-.2H11V8c0-.8.3-1.5.9-2.1.6-.6 1.3-.9 2.1-.9h2z" fill-rule="evenodd"/></svg>',
    'upload': '<svg width="24" height="24"><path d="M18 19v-2a1 1 0 012 0v3c0 .6-.4 1-1 1H5a1 1 0 01-1-1v-3a1 1 0 012 0v2h12zM11 6.4L8.7 8.7a1 1 0 01-1.4-1.4l4-4a1 1 0 011.4 0l4 4a1 1 0 11-1.4 1.4L13 6.4V16a1 1 0 01-2 0V6.4z" fill-rule="nonzero"/></svg>',
    'unordered-list': '<svg width="24" height="24"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.5 6.5C5.32843 6.5 6 5.82843 6 5C6 4.17157 5.32843 3.5 4.5 3.5C3.67157 3.5 3 4.17157 3 5C3 5.82843 3.67157 6.5 4.5 6.5ZM8.25 5C8.25 4.58579 8.58579 4.25 9 4.25H22C22.4142 4.25 22.75 4.58579 22.75 5C22.75 5.41421 22.4142 5.75 22 5.75H9C8.58579 5.75 8.25 5.41421 8.25 5ZM9 11.25C8.58579 11.25 8.25 11.5858 8.25 12C8.25 12.4142 8.58579 12.75 9 12.75H22C22.4142 12.75 22.75 12.4142 22.75 12C22.75 11.5858 22.4142 11.25 22 11.25H9ZM9 18.25C8.58579 18.25 8.25 18.5858 8.25 19C8.25 19.4142 8.58579 19.75 9 19.75H22C22.4142 19.75 22.75 19.4142 22.75 19C22.75 18.5858 22.4142 18.25 22 18.25H9ZM6 12C6 12.8284 5.32843 13.5 4.5 13.5C3.67157 13.5 3 12.8284 3 12C3 11.1716 3.67157 10.5 4.5 10.5C5.32843 10.5 6 11.1716 6 12ZM4.5 20.5C5.32843 20.5 6 19.8284 6 19C6 18.1716 5.32843 17.5 4.5 17.5C3.67157 17.5 3 18.1716 3 19C3 19.8284 3.67157 20.5 4.5 20.5Z" fill="#787878"/></svg>',
    'warning': '<svg width="24" height="24"><path d="M19.8 18.3c.2.5.3.9 0 1.2-.1.3-.5.5-1 .5H5.2c-.5 0-.9-.2-1-.5-.3-.3-.2-.7 0-1.2L11 4.7l.5-.5.5-.2c.2 0 .3 0 .5.2.2 0 .3.3.5.5l6.8 13.6zM12 18c.3 0 .5-.1.7-.3.2-.2.3-.4.3-.7a1 1 0 00-.3-.7 1 1 0 00-.7-.3 1 1 0 00-.7.3 1 1 0 00-.3.7c0 .3.1.5.3.7.2.2.4.3.7.3zm.7-3l.3-4a1 1 0 00-.3-.7 1 1 0 00-.7-.3 1 1 0 00-.7.3 1 1 0 00-.3.7l.3 4h1.4z" fill-rule="evenodd"/></svg>',
  }
});